<script>
import { required, email } from "vuelidate/lib/validators";
import { userService } from "@/services/user.service";

export default {
  data() {
    return {
      user: {
        email: "",
        password: "",
      },
      submitted: false,
      errors: {},
      loading: false,
      showPassword: false
    };
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
    passwordFieldType() {
      return this.showPassword ? 'text' : 'password';
    }
  },
  created() {
    document.body.classList.add("auth-body-bg");
  },
  validations: {
    user: { email: { required, email }, password: { required } },
  },
  methods: {
    // Try to log the user in with the username
    // and password they provided.
    async tryToLogIn() {
      this.$store.dispatch("notification/clear");
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {
        const { email, password } = this.user;
        if (email && password) {
          try {
            this.errors = {};
            this.loading = true;
            var response = await userService.login(this.user);

            this.$store.dispatch("notification/success", response.data.message);
            this.$store.dispatch("auth/login", response.data.user);
            this.loading = false;

            // check if this user has not paid
            if (this.$store.state.auth.user.payment_date == null) {
              this.$router.push("/payment");
            } else {
              this.$router.push("/");
            }
          } catch (error) {
            this.loading = false;
            console.log(error);
            switch (error.response.status) {
              case 422:
                this.errors = error.response.data.errors;
                this.user.password = "";
                break;
              case 401:
                this.$store.dispatch(
                  "notification/error",
                  error.response.data.message
                );
                break;
              case 403:
                this.$store.dispatch(
                  "notification/error",
                  error.response.data.message
                );
                this.$router.push({
                  name: "verify-email",
                  params: { email: this.user.email },
                });
                break;
              case 500:
                this.$store.dispatch(
                  "notification/error",
                  error.response.data.message
                );
                break;
              default:
                this.$store.dispatch(
                  "notification/error",
                  "Something went wrong. Please try again!"
                );
                break;
            }
          }
        }
      }
    },
  },
};
</script>

<template>
  <div>
    <div class="home-btn d-none d-sm-block">
      <a href="/">
        <i class="mdi mdi-home-variant h2 text-white"></i>
      </a>
    </div>
    <div>
      <div class="container-fluid p-0">
        <div class="row no-gutters">
          <div class="col-lg-4">
            <div
              class="
                authentication-page-content
                p-4
                d-flex
                align-items-center
                min-vh-100
              "
            >
              <div class="w-100">
                <div class="row justify-content-center">
                  <div class="col-lg-9">
                    <div>
                      <div class="text-center">
                        <div>
                          <a href="#" class="logo">
                            <img
                              src="@/assets/images/logo-dark.png"
                              height="20"
                              alt="logo"
                            />
                          </a>
                        </div>

                        <h4 class="font-size-18 mt-0">Welcome Back !</h4>
                        <p class="text-muted">
                          Sign in to continue to Cadlands.
                        </p>
                      </div>

                      <b-alert
                        :variant="notification.type"
                        class="mt-1"
                        v-if="notification.message"
                        show
                        dismissible
                        @dismissed="$store.dispatch('notification/clear')"
                        >{{ notification.message }}</b-alert
                      >

                      <div class="p-2 mt-2">
                        <form
                          class="form-horizontal"
                          @submit.prevent="tryToLogIn"
                        >
                          <div class="form-group auth-form-group-custom mb-2">
                            <i class="ri-mail-line auti-custom-input-icon"></i>
                            <label for="email">Email</label>
                            <input
                              autofocus
                              type="email"
                              v-model="user.email"
                              class="form-control"
                              id="email"
                              placeholder="Enter email"
                              :class="{
                                'is-invalid':
                                  (submitted && $v.user.email.$error) ||
                                  errors.email,
                              }"
                            />
                            <div
                              v-if="
                                (submitted && $v.user.email.$error) ||
                                errors.email
                              "
                              class="invalid-feedback"
                            >
                              <span v-if="errors.email">{{
                                erros.email[0]
                              }}</span>
                              <span v-if="!$v.user.email.required"
                                >Email is required.</span
                              >
                              <span v-if="!$v.user.email.email"
                                >Please enter valid email.</span
                              >
                            </div>
                          </div>

                          <div class="form-group auth-form-group-custom mb-1">
                            <i
                              class="ri-lock-2-line auti-custom-input-icon"
                            ></i>
                            <label for="userpassword">Password</label>
                            <input
                              v-model="user.password"
                              :type="passwordFieldType"
                              class="form-control"
                              id="userpassword"
                              placeholder="Enter password"
                              :class="{
                                'is-invalid':
                                  (submitted && $v.user.password.$error) ||
                                  errors.password,
                              }"
                            />
                            <div
                              v-if="
                                (submitted && !$v.user.password.required) ||
                                errors.password
                              "
                              class="invalid-feedback"
                            >
                              <span v-if="errors.password">{{
                                erros.password[0]
                              }}</span>
                              <span v-if="!$v.user.password.required"
                                >Password is required.</span
                              >
                            </div>
                          </div>
                          <div class="text-right custom-control custom-checkbox text-muted">
                              <input type="checkbox" id="showPassword" class="custom-control-input" v-model="showPassword"/>
                              <label
                              class="custom-control-label"
                              for="showPassword"
                              > Show Password
                              </label>
                          </div>

                          <!-- <div
                            class="custom-control text-center custom-checkbox"
                          >
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              id="customControlInline"
                            />
                            <label
                              class="custom-control-label"
                              for="customControlInline"
                              >Remember me</label
                            >
                          </div> -->

                          <div class="mt-3 text-center">
                            <button
                              class="
                                btn btn-primary
                                w-md
                                waves-effect waves-light
                              "
                              type="submit"
                            >
                              Log In
                              <b-spinner
                                v-show="loading"
                                small
                                class="align-middle"
                              ></b-spinner>
                            </button>
                          </div>

                          <div class="mt-4 text-center">
                            <router-link
                              tag="a"
                              to="/forgot-password"
                              class="text-muted"
                            >
                              <i class="mdi mdi-lock mr-1"></i> Forgot your
                              password?
                            </router-link>
                          </div>
                        </form>
                      </div>

                      <div class="mt-5 text-center">
                        <p>
                          Don't have an account ?
                          <router-link
                            tag="a"
                            to="/register"
                            class="font-weight-medium text-primary"
                            >Register</router-link
                          >
                        </p>
                        <p>
                          © {{ currentYear }} Cadlands. 
                          <!-- Powered by
                          <a href="https://encodeup.com" target="blank"
                            >Encodeup</a
                          > -->
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-8">
            <div class="authentication-bg">
              <div class="bg-overlay"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>